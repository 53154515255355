import React from 'react'
import { Grid } from '@mui/material'
import Marquee from "react-fast-marquee";
function Desktopthree() {
  return (
    <div className='footer'>


        
<Grid container>
        
         <Grid item md={12} xs={12} lg={12} className='centerall'>
       
        
         <Marquee className='backofit'>
       <p className='fotx'> &nbsp;&nbsp;  &nbsp;&nbsp; Buy $Degen or Cry &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; Buy $Degen or Cry &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; Buy $Degen or Cry &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; Buy $Degen or Cry &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; Buy $Degen or Cry &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; Buy $Degen or Cry &nbsp;&nbsp; &nbsp;&nbsp; </p>
  </Marquee>
         </Grid>
          </Grid>

    </div>
  )
}

export default Desktopthree