import React from 'react'
import { Grid } from '@mui/material'
import anos from './reeet.jpeg'
import anoss from './1.png'
import reet from './logoreet.png'
import reetro from './rto.png'
import reetros from './cree.png'
import fr from './btb.jpeg'
import fra from './btt.png'
import frb from './3.png'
import twitter from './twitter.png'
import telegram from './telegram.png'
import telegrams from './buyr.png'
import telegramss from './chart.png'
import lets from './letal.png'
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import c1 from './phantom.png'
import c2 from './solana.png'
import c3 from './rads.webp'
import c4 from './deb.png'

import ch1 from './c1.png'
import ch2 from './c2.png'


function Desktop() {
  return (
    <div className=''>
     
          <Grid container className='backall'>
          <Grid item md={2} xs={2} lg={2} className='centerall'>

            <img src={reet} style={{width:"80%"}}/>
         
         </Grid>
         <Grid item md={2} xs={2} lg={2} className='centerall'>



</Grid>
<Grid item md={2} xs={2} lg={2} className='centerall'>
<a href='https://twitter.com/reetonsol'><img src={twitter} style={{width:"70%"}}/></a>


</Grid>
<Grid item md={2} xs={2} lg={2} className='centerall'>
<a href='https://t.me/ReetSol'><img src={telegram} style={{width:"70%"}}/></a>


</Grid>
<Grid item md={2} xs={2} lg={2} className='centerall'>
<a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=GPJsZB9RBrog6g6HDJLFQECyHUHwy9npRMSSkknDcoC5&fixed=in'><img src={telegrams} style={{width:"70%"}}/></a>


</Grid>
<Grid item md={2} xs={2} lg={2} className='centerall'>
<a href='https://www.dextools.io/app/en/solana/pair-explorer/6UpsQwiuxompJEAwRMuMVm536JJHaZzwnKNBnndPmAfu?t=1713303201494'><img src={telegramss} style={{width:"70%"}}/></a>


</Grid>
          </Grid>
       
        
          <Grid container>
        
         <Grid item md={6} xs={12} lg={6} className='centerall'>
        
         
        
         <p className='anon_p'>
       CA : 
GPJsZB9RBrog6g6HDJLFQECyHUHwy9npRMSSkknDcoC5
</p>
       <img src={anoss} className='image_anos'/>
        

         </Grid>
         <Grid item md={6} xs={12} lg={6} className='centerall'>
          <br/>  <br/>  <br/>  <br/>
                <img src={anos} style={{width:"90%"}}/>
         </Grid>


         
         <Grid item md={12} xs={12} lg={12} className='centerall'>
                <img src={reetro} className='image_anosro'/>
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'>
                <img src={fr} className='image_anosro'/>
         </Grid>
        

         <Grid item md={12} xs={12} lg={12} className='centerall'>
                <img src={frb} className='image_anosro'/>
         </Grid>

         <Grid item md={12} xs={12} lg={12} className='centerall'>
                <img src={reetros} className='image_anosro'/>
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'>
                <img src={lets} style={{width:"100%"}}/>
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'>
                <img src={fra} style={{width:"70%"}}/>
         </Grid>
          </Grid>

     
       

    </div>
  )
}

export default Desktop